import './App.css';

function App() {
  return (
    <div className="App">
      <div className="container">
        <h1>
          Client Data Solutions
        </h1>
        <h2>
          Simple. Secure. Affordable.
        </h2>
      </div>
    </div>
  );
}

export default App;
